<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" color="primary" dark small>
        <v-icon left>mdi-account-multiple-plus</v-icon>
        Lote
      </v-btn>
    </template>
    <v-card class="v-card-profile">
      <v-app-bar color="primary" dark flat>
        <v-toolbar-title class="body-2">
          <!-- <v-spacer></v-spacer> -->
          Matricular em Lote por E-mail
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-layout row wrap>
            <v-flex lg12 sm12>
              <v-text-field
                label="Título do curso"
                disabled
                readonly
                v-model="curso.titulo"
              >
              </v-text-field>
            </v-flex>
            <v-flex lg12 sm12>
              <v-text-field
                label="ID"
                disabled
                readonly
                v-model="curso['.key']"
              >
              </v-text-field>
            </v-flex>
            <!-- <v-flex lg12 sm12>
              <v-checkbox
                v-model="importar"
                label="Importar matriculados de outro curso"
              ></v-checkbox>
            </v-flex> -->
            <v-flex lg12 sm12>
              <v-combobox
                v-if="importar"
                v-model="matricula.importar"
                :items="cursos"
                label="Curso que deseja importar os alunos"
                clearable
                item-text="titulo"
                small-chips
              ></v-combobox>
            </v-flex>
            <v-flex lg12 sm12>
              <v-textarea
                label="Selecione os alunos que deseja matricular neste curso"
                v-if="!importar"
                v-model="matricula.lote"
              ></v-textarea>
            </v-flex>
            <v-flex lg12 sm12>
              <v-combobox
                v-if="importar"
                v-model="matricula.importar"
                :items="cursos"
                label="Curso que deseja importar os alunos"
                clearable
                item-text="titulo"
                small-chips
              ></v-combobox>
            </v-flex>
            <v-flex lg12 sm12>
              <v-text-field
                label="Valor Pago"
                v-model="matricula.valorPago"
                :value="curso.valor"
              >
              </v-text-field>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-3">
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click.native="cancelar()"
          >Cancelar</v-btn
        >
        <v-btn color="primary" @click.native="salvar()">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import toastr from "toastr";
import firebase from "firebase/app";
import { db } from "@/db";
export default {
  name: "MatriculaLoteEmail",
  props: {
    curso: Object,
  },
  firebase: {
    items: db.ref("/users/"),
    cursos: db.ref("/cursos/"),
  },
  data() {
    return {
      items: [],
      cursos: [],
      dialog: false,
      valid: true,
      password: null,
      importar: false,
      matricula: {
        valorPago: this.curso.valor,
        cursoKey: this.curso[".key"],
      },
    };
  },
  computed: {},
  methods: {
    getValues(object) {
      let keys = Object.keys(object);
      let values = Object.values(object);
      // console.log(values)
      keys.forEach(function (key, index) {
        if (key) {
          values[index].hash = key;
          values[index].key = key;
        }
      });
      return values;
    },
    salvar() {
      let count = 0;
      let matricula = this.matricula;
      let curso = {
        valorPago: matricula.valorPago,
      };
      const cursoKey = this.curso['.key'];
      // const cursoKey = matricula.cursoKey;
      if (matricula.importar) {
        const matriculados = this.getValues(matricula.importar.matriculados);
        // console.log(matriculados)
        matriculados.forEach((aluno) => {
          this.matricular(
            matricula,
            aluno,
            matricula.importar.matriculados.length
          );
        });
      } else {
        let emails = matricula.lote.split("\n");
        if (emails) {
          // emails.forEach((email, index) => {
          // if (email) {
          toastr.success("Iniciando a matrícula...", "MCS Assessoria e Treinamentos");
          this.$http.defaults.headers.common["Authorization"] =
            "Bearer " + this.$store.state.user.refreshToken;
          // console.log(newUser)
          this.$http
            .post("/matricular", {
              emails: emails,
              cursoKey: cursoKey,
              valorPago: matricula.valorPago,
            })
            .then((response) => {
              console.log(response);
              toastr.success(response.data.msg, "MCS Assessoria e Treinamentos");
              this.dialogUser = false;
            })
            .catch((error) => {
              // console.log(error.response.data.error);
              toastr.error(
                "Algo Inesperado aconteceu: " + error.response.data.error,
                "MCS Assessoria e Treinamentos"
              );
            });
          // }
          // });
        }
        // matricula.alunos.forEach((aluno, index) => {
        //   if (aluno && aluno.uid) {
        //     this.matricular(matricula, aluno, matricula.alunos.length);
        //   }
        // });
      }
      // this.matricula = {};
      this.dialog = false;
    },
    matricular(matricula, aluno, quantidade) {
      let count = 0;
      let curso = {
        valorPago: matricula.valorPago,
      };
      const cursoKey = this.curso['.key'];
      // const cursoKey = matricula.cursoKey;
      db.ref("/users/" + aluno.uid + "/cursos/" + cursoKey)
        .set(curso)
        .then(() => {
          db.ref("/cursos/" + cursoKey + "/matriculados/" + aluno.uid)
            .set(aluno)
            .then(() => {
              count++;
              if (index + 1 === quantidade) {
                toastr.success(
                  count + " alunos foram matriculados!",
                  "MCS Assessoria e Treinamentos"
                );
              }
            });
        });
    },
    cancelar() {
      this.dialog = false;
    },
  },
  created() {
    // console.log(this.userEdit)
  },
  mounted() {
    // console.log(process.env.VUE_APP_ROOT_API)
  },
};
</script>
